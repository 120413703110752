import React from 'react';
import "./about.css";
import { FaBookmark } from "react-icons/fa6";



export default (About) => {

    return (
        <div className='block-main-container'>
            <div className='block-desc-wrap'>
                <h2>About Us</h2>
                <p>Why did we open this organization?</p>
                
            </div>
            <div className='about-callout-wrap'>
                <div className='about-callout'>
                    <div className='icon-wrap'>
                        <FaBookmark className='icon'/>
                    </div>
                    <div className='content-wrap'>
                        <h3>Our Story</h3>
                        <p>Having had trodden through the muddy waters of the complex admission system with no help from our alma mater, we were lost, tired, and frustrated. From that moment on, we decided that no one else has to have this experience. Thus, we bring you, US Resources Nepal.</p>
                    </div>
                </div>
                <div className='about-callout'>
                    <div className='icon-wrap'>
                        <FaBookmark className='icon' />
                    </div>
                    <div className='content-wrap'>
                        <h3>Our Vision</h3>
                        <p>USRN aims to provide a centralized and focused resource for Nepalese students. We hope to be the stop site for students dreaming of studying in the US.</p>
                    </div>
                </div>
                <div className='about-callout'>
                    <div className='icon-wrap'>
                    <FaBookmark className='icon' />
                    </div>
                    <div className='content-wrap'>
                        <h3>Our Values</h3>
                        <p>
Accurate and straight to the point information for all your needs.
Data driven and legit information
Organized, connected and centralized resources</p>
                    </div>
                </div>
            </div>
</div>
)

}