import React, {useState} from 'react';
import axios from 'axios';
import { useNavigate, useLocation , Link } from 'react-router-dom';

export default function Otp() {

    const [otp, setOTP] = useState('');

    const handleChange = (e) => {
        setOTP(e.target.value);
      };

    const navigate = useNavigate();
    const location = useLocation();

    const originalOtp = location.state?.otp;
    const originalEmail = location.state?.email;


    console.log("otp" + originalOtp + originalEmail);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (originalOtp == otp) {
           navigate("/resetPassword", {state : {email: originalEmail}})
        } else {
            console.log("resetPassword");
        }
    };

    return (
        <div>
            <div className="form-box">
            <header className='login__header'>Enter OTP code sent to your email</header>
            <div className="input-Field">
            <form onSubmit={handleSubmit} >
                <div className='field input-field'>
                <input type="number" name="otp" placeholder="Enter your OTP code" value={otp} onChange={handleChange} />
                </div>
                <div className="field button-field">
                <button>Submit</button>
                </div>
                <div className="form-link">
                <span>Don't have an account? <Link to="/register" className="link signup-link">Create an account</Link></span>
                </div>
            </form>
            </div>
        </div>
        </div>
    )
}