import React, {useState, useEffect} from "react";
import axios from "axios";
import PostCard from "../PostCard/PostCard";
import blogPic from "../../assets/blogPic.jpg"
import { Link } from "react-router-dom";
import "./Posts.css"
import Title from "../page-title-block/Title";

export default function Posts() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);


    //have a loading state while the data is getting loaded
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('http://localhost:8080/posts');
                setPosts(res.data);
                setLoading(false);
                console.log(res.data);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="posts-main-container">
            <Title title = "Blogs" />
            <div className="posts-container-wrap">
            <div className="posts-wrap">
            {loading ? (<h2>loading..</h2>):posts.map(post => (
                post.isVerified && !post._ama &&
                <Link to = {`/post/${post.urlSlug}`} className="home_posts"><PostCard key = {post.id} id = {post.id} title = {post.title} metaBody = {post.meta_data} imgSrc = {blogPic} authorFirstName = {post.account.firstName} authorLastName = {post.account.lastName} createdAt = {post.createdAt} urlSlug = {post.urlSlug}/></Link>             
            ))}
            </div>
            </div>
        </div>
    )

} 