import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Login from "./components/auth/Login";
import CreatePost from "./components/CreatePost/CreatePost";
import Register from "./components/auth/Register";
import Post from "./components/Post/Post";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer"
import Profile from "./components/Profile/Profile";
import Posts from "./components/Post/Posts";
import Reset from "./components/auth/Reset";
import Otp from "./components/auth/Otp";
import ResetPassword from "./components/auth/ResetPassword";
import AMA from "./components/AMA/ama";

function App() {
  

  return (
    
      <BrowserRouter>
      <Navbar />
        <main>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/login" element={<Login />}/>
          <Route path = "/login/reset" element = {<Reset />} /> 
          <Route path = "/login/reset/otp" element = {<Otp />} />
          <Route path = "/createPost" element = {<CreatePost />}  />     
          <Route path = "/resetPassword" element = {<ResetPassword/>} />
          <Route path = "/register" element = {<Register />} />
          <Route path = "/post/:postUrlSlug" element = {<Post />}/>
          <Route path = "/profile" element = {<Profile/>}/>
          <Route path = "/posts" element = {<Posts/>} />
          <Route path = "/ama" element = {<AMA/>} />
        </Routes>
        </main>
      <Footer />
        
      </BrowserRouter>
    
  );

}

export default App;
