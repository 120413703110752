import React from 'react';
import { FaBookmark, FaLinkedinIn } from "react-icons/fa6";
import sanij from "../../../assets/sanij.jpeg";
import oshan from "../../../assets/oshan.jfif";
import prabesh from "../../../assets/prabesh.jfif";
import "./Team.css";
import { Link } from "react-router-dom";


export default function Team() {
    return (
        <div className='block-main-container'>
            <div className='block-desc-wrap'>
                <h2>Our Team</h2>
                <p>Why did we open this organization?</p>
            </div>
            <div className='team-callout-wrap'>
                <div className='team-callout'>
                    <div className='team-intro-wrap'>
                        <img className= "team-image" src={sanij} />
                        <div className='team-title'>
                            <h3>Sanij Shrestha</h3>
                            <p>Founder</p>
                        </div>
                    </div>
                    <div className='team-content-wrap'>
                        <p>I am a Junior majoring in CS & minoring in Business and Data Science at Gettysburg College.</p><br></br>
                        <p>- Founded the organization and led the development of the website.</p>
                    </div>
                    <div className='linkedin-team'>
                        <Link to="https://www.linkedin.com/in/sanij-shrestha/"><FaLinkedinIn /></Link>
                    </div>
                </div>
                <div className='team-callout'>
                    <div className='team-intro-wrap'>
                        <img className= "team-image" src={prabesh} />
                        <div className='team-title'>
                            <h3>Prabesh Bista</h3>
                            <p>Co-founder</p>
                        </div>
                    </div>
                    <div className='team-content-wrap'>
                        <p>I am a Junior majoring in CS & Mathematical Economics at Gettysburg College.</p>
                        <br></br>
                        <p>- Previously helped in founding the organization</p>
                    </div>
                    <div className='linkedin-team'>
                        <Link to="https://www.linkedin.com/in/sanij-shrestha/"><FaLinkedinIn /></Link>
                    </div>
                </div>
                <div className='team-callout'>
                    <div className='team-intro-wrap'>
                    <img className= "team-image" src={oshan} />
                    <div className='team-title'>
                            <h3>Oshan Maharjan</h3>
                            {/* <p></p> */}
                        </div>
                    </div>
                    <div className='team-content-wrap'>
                        <p>I am a Senior majoring in CS at Northern Kentucky University.</p><br></br>
                        <p>- Assisted in developing the frontend and setting up the Database; Managed content.</p>
                    </div>
                    <div className='linkedin-team'>
                        <Link to="https://www.linkedin.com/in/sanij-shrestha/"><FaLinkedinIn /></Link>
                    </div>
                </div>
            </div>
</div>
    )
}