    import React, {useEffect, useState} from 'react';
    import img from "../../../assets/blogPic.jpg";
    import "./Featured.css";
    import { Link } from "react-router-dom";
    import axios from "axios";


    export default function Featured() {
        const [posts, setPosts] = useState([]);


        useEffect(() => {
            axios.get('http://localhost:8080/posts') 
                .then(res => {
                    setPosts(res.data.slice(0, 4));  
                    console.log(posts);
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },[]);

        return (
            <div className='block-main-container'>
            <div className='block-desc-wrap'>
                <h2>Featured</h2>
                <p>Most viewed blogs of this week</p>
            </div>
            <div className='featured-block-wrap'>
                {posts.map(post => (
                    post.isVerified && (
                        <div className='featured-inner-block-wrap' key={post.id}>
                            <div className='featured-content-wrap'>
                                <div className='featured-title-wrap'>
                                    <Link to={`/post/${post.urlSlug}`} className='featured-post-title'><h3>{post.title}</h3></Link>
                                    <p>{post.meta_data}</p>
                                </div>
                            </div>                        
                            </div>
                    )
                ))}
            </div>
        </div> 
        )
    }