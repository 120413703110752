import React from 'react';
import { Link } from "react-router-dom";
import {useLocation, useParams} from 'react-router-dom';
import "./Title.css"


export default function Title(props) {
    let location = useLocation();

    return (
        <div className='title-block-wrap'>
            <h1 className='page-title-h1'>{props.title}</h1>
            <div className='breadcrumb'>
                <Link to={"/"} className='location'>Home </Link>
                <span>&gt;&gt;</span>
                <Link to ={location} className='location second-location'> {props.title}</Link>
            </div>
        </div>
    )

}