import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./Login.css";
import { FaEye } from "react-icons/fa6";

import { AiFillEyeInvisible } from "react-icons/ai";

const Login = () => {

  const [user, setUser] = useState({ email: '', password: '' });
  const [closed, setClosed] = useState(true);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:8080/login', user)
      .then(response => {
        console.log(response);
        localStorage.setItem("user", JSON.stringify(response.data));
        window.location.href = "/";
      })
      .catch(error => {
        console.error('Login failed:', error);
      });
  };

  const navigate = useNavigate();

    const handleForgotPasswordClick = () => {
        navigate('/login/reset');
    };

  function handleClosed() {
    setClosed(!closed);
    console.log(closed);
  }

  return (
    <div className="container">
      <div className="form-box">
        <header className='login__header'>Login</header>
        <div className="input-Field">
          <form onSubmit={handleSubmit}>
            <div className='field input-field'>
              <input type="text" name="email" placeholder="Enter your email" value={user.email} onChange={handleChange} />
            </div>
            <div className='field input-field'>
              <input type= {closed ? "password" : "text"} name="password" placeholder="Password" value={user.password} onChange={handleChange} />
              {closed ? <AiFillEyeInvisible onClick={() => handleClosed()}/> : <FaEye onClick={() => handleClosed()} />}
            </div>
            <div className="field button-field">
              <button>Login</button>
            </div>
            <div className="form-link">
              <a href="#" className="forgot-pass" onClick={() => handleForgotPasswordClick()}>Forgot password?</a>
            </div>
            <div className="form-link">
              <span>Don't have an account? <Link to="/register" className="link signup-link">Create an account</Link></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
