import React from 'react';
import "./FrontPage.css"
import img from '../../../assets/penn-hall.jpg';

export default function FrontPage() {
    return (
        <div className='main-container-frontpage'>
            <div className='sub-container'>
                <div className='main-block'>
                    <div className='block-text-wrapper'>
                        <h1 className='main-title'>US RESOURCES  NEPAL</h1>
                        <h5>A free resource hub for international students</h5>
                        <span className='slogan'>aspiring to study at the United States of America</span>
                    </div>
                </div>
            </div>
        </div>
    )
}