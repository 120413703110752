import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function ResetPassword() {
    
    const [password, setPassword] = useState({newPassword: '', confirmPassword: ''});

    const location = useLocation();

    const email = location.state?.email;

    const handleChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
      };

    
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('http://localhost:8080/resetPassword', {"email": email, "password": password.newPassword})
         .then(response => {
            window.location.href("/");
            console.log(response);
         })
          .catch(error => {
          console.error('Login failed:', error);
          });
      };

    return (
        <div>
            <div className="form-box">
            <header className='login__header'>Reset Password</header>
            <div className="input-Field">
            <form onSubmit={handleSubmit} >
                <div className='field input-field'>
                    <input type="password" name="newPassword" placeholder="Enter your new password" value={password.newPassword} onChange={handleChange} />
                    <input type="password" name="confirmPassword" placeholder="Confirm password" value={password.confirmPassword} onChange={handleChange} />
                </div>
                <div className="field button-field">
                <button>Change Password</button>
                </div>
            </form>
            </div>
        </div>
        </div>
    )
}