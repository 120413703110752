import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Posts from "../Post/Posts";
import FrontPage from "./FrontPage/frontpage";
import About from "./About/about";
import "./Home.css";
import Team from "./Team/Team";
import Featured from "./Featured/Featured";
import Contributors from "./Contributors/Contributors";


const insertScript = (id, parentElement) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = id;
  
    let url = window.location.origin + window.location.pathname;
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
  
    script.innerHTML = `
      var remark_config = {
        host: "http://localhost:8003",
        site_id: "example-name",
        url: "${url}",
        theme: "dark",
        components: ["embed"],
      };
      !function(e,n){for(var o=0;o<e.length;o++){var r=n.createElement("script"),c=".js",d=n.head||n.body;"noModule"in r?(r.type="module",c=".mjs"):r.async=!0,r.defer=!0,r.src=remark_config.host+"/web/"+e[o]+c,d.appendChild(r)}}(remark_config.components||["embed"],document);
    `;
    parentElement.appendChild(script);
  };
  
  const removeScript = (id, parentElement) => {
    const script = document.getElementById(id);
    if (script) {
      parentElement.removeChild(script);
    }
  };
  
  const recreateRemark42Instance = () => {
    if (window.REMARK42) {
      window.REMARK42.destroy();
      window.REMARK42.createInstance(window.remark_config);
    }
  };


export default function Home() {

    const location = useLocation().pathname;

    useEffect(() => {
        // Insert script when component mounts
        insertScript("comments-script", document.body);
    
        // Remove script when component unmounts
        return () => removeScript("comments-script", document.body);
      }, [location]);
    
      useEffect(() => {
        recreateRemark42Instance();
      }, [location]);

    const [user, setUser] = useState("");

    useEffect(() => {
        const currUser = localStorage.getItem("user")

        if(currUser) {
            setUser(currUser);
        }
    }, [])

    function Logout() {
        localStorage.removeItem("user")
        setUser("");
        window.location.reload();
    }

    return (
        <div>
            <FrontPage/>
            <Featured />
            <About />
            <Contributors/>
            <Team />
            <div id="remark42"></div>
        </div>
        
    )

}